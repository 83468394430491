import { Portal, Select } from '@ark-ui/react';
import {
  IconDefinition,
  faChevronDown,
  faUpDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useOverflowDetector } from 'react-detectable-overflow';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import { cn } from 'src/shared/utils';

export type ArkSelectItem = {
  label: string;
  value: string | number;
  icon?: IconDefinition;
  paywall?: ShowSubscriptionsReason;
};

type Size = 'xs' | 'sm' | 'lg';
type Placement =
  | 'top-start'
  | 'top-end'
  | 'right-start'
  | 'right-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'left-start'
  | 'left-end';

type ArkSelectProps = {
  onChange: (e: string) => void;
  items: ArkSelectItem[];
  defaultValue: string | number;
  size?: Size;
  placement?: Placement;
  icon?: IconDefinition;
};

export const ArkSelect = ({
  onChange,
  items,
  defaultValue,
  size = 'lg',
  placement = 'bottom-start',
  icon,
}: ArkSelectProps) => {
  const { ref, overflow } = useOverflowDetector({});
  const modal = useModalContext();

  const addPremiumModal = (requires: ShowSubscriptionsReason) => {
    modal.addModal({
      children: <Subscriptions showReason={requires} source="chat_settings" />,
    });
  };

  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [previousValue, setPreviousValue] = useState(defaultValue);
  const getSelectedIcon = () => {
    return items.find((item) => item.value === selectedValue)?.icon;
  };
  const [selectedIcon, setSelectedIcon] = useState<IconDefinition | undefined>(
    getSelectedIcon()
  );

  const handleValueChange = (value: string) => {
    const selectedItem = items.find((item) => item.value === value);

    if (selectedItem && selectedItem.paywall) {
      addPremiumModal(selectedItem.paywall);
      setSelectedValue(previousValue);
    } else {
      setPreviousValue(value);
      setSelectedValue(value);
      onChange(value);
    }
  };

  useEffect(() => {
    setSelectedIcon(getSelectedIcon());
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <Select.Root
      items={items}
      onValueChange={(e) => handleValueChange(e.value[0])}
      value={[selectedValue as string]}
      defaultValue={[defaultValue as string]}
      positioning={{ placement: placement }}
    >
      {/* <Select.Label>Framework</Select.Label> */}
      <Select.Control>
        <Select.Trigger
          className={cn(
            'tw-flex tw-flex-row tw-border tw-text-white tw-border-horny-gray-600 tw-rounded-md tw-bg-horny-gray-900 tw-font-extrabold tw-shadow tw-justify-between tw-items-center',
            size === 'xs' && 'tw-text-sm tw-px-3 tw-py-2 tw-w-20',
            size === 'sm' && 'tw-text-sm tw-px-3 tw-py-2 tw-w-32',
            size === 'lg' && 'tw-text-xl tw-px-5 tw-py-2 tw-w-60'
          )}
        >
          <div
            className={cn(
              'tw-flex tw-flex-row tw-gap-1 tw-items-center tw-leading-none'
            )}
          >
            {icon && <FontAwesomeIcon icon={icon} size="xs" />}
            {selectedIcon && <FontAwesomeIcon icon={selectedIcon} size="xs" />}
            <Select.ValueText
              defaultValue={items[0].label}
              defaultChecked={true}
            />
          </div>
          <Select.Indicator>
            <FontAwesomeIcon icon={faUpDown} size="xs" />
          </Select.Indicator>
        </Select.Trigger>
        {/* <Select.ClearTrigger>Clear</Select.ClearTrigger> */}
      </Select.Control>
      <Portal>
        <Select.Positioner style={{ zIndex: 999 }}>
          <Select.Content
            className={cn(
              'tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-md tw-shadow',
              size === 'xs' && 'tw-w-20 tw-px-3 tw-py-2',
              size === 'sm' && 'tw-w-40 tw-px-3 tw-py-2',
              size === 'lg' && 'tw-w-60 tw-px-5 tw-py-3'
            )}
          >
            <Select.ItemGroup
              className="tw-flex tw-flex-col tw-gap-4 tw-max-h-80 tw-overflow-y-scroll tw-scrollbar-hide"
              ref={ref as React.RefObject<HTMLDivElement>}
            >
              {/* <Select.ItemGroupLabel>Frameworks</Select.ItemGroupLabel> */}
              {items.map((item) => (
                <Select.Item
                  key={item.label}
                  item={item}
                  className={cn(
                    'tw-flex tw-flex-row tw-gap-4 tw-cursor-pointer tw-pointer-events-auto',
                    size === 'xs' && 'tw-text-sm',
                    size === 'sm' && 'tw-text-sm',
                    size === 'lg' && 'tw-text-xl'
                  )}
                  onClick={() => {
                    //addPremiumModal(item.paywall!);
                  }}
                >
                  <Select.ItemText>
                    <div
                      className={cn(
                        'tw-flex tw-flex-row tw-gap-1 tw-items-center',
                        item.paywall && 'tw-opacity-50'
                      )}
                    >
                      {item.icon && (
                        <div
                          className={cn(
                            'tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4'
                          )}
                        >
                          <FontAwesomeIcon icon={item.icon} size={'sm'} />
                        </div>
                      )}
                      <div>{item.label}</div>
                    </div>
                  </Select.ItemText>
                  <Select.ItemIndicator>✓</Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.ItemGroup>
            <div
              className={cn(
                !overflow && 'tw-hidden',
                'tw-text-xxs tw-w-full tw-flex tw-flex-col tw-pt-1 tw-items-center tw-border-t tw-border-t-horny-gray-600'
              )}
            >
              <div className="tw-w-fit">Scroll</div>
              <FontAwesomeIcon icon={faChevronDown} className="tw-w-fit" />
            </div>
          </Select.Content>
        </Select.Positioner>
      </Portal>
      <Select.HiddenSelect />
    </Select.Root>
  );
};
