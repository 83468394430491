import { faG2aLogo } from '@awesome.me/kit-4ef019de7a/icons/kit/custom';
import { faCoinVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { CreditsPrices } from 'src/@types/common';
import { getIconUrl, Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { SheenBlock } from 'src/components/Shared/SheenBlock';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import EnterCodePopup from 'src/components/Subscription/EnterCodePopup';
import { useNutakuPayment } from 'src/components/Subscription/NutakuPayment';
import { useModalContext } from 'src/context/Modal.Context';
import { useNutakuContext } from 'src/context/Nutaku.Context';

export type CreditsElementProps = {
  creditsPrice: CreditsPrices;
};

export default function CreditsShopElement({
  creditsPrice,
}: CreditsElementProps) {
  const { t } = useTranslation();
  const { isNutaku } = useNutakuContext();
  const { addModal } = useModalContext();
  const { nutakuCreatePayment } = useNutakuPayment({
    NutakuPayment: {
      itemId: creditsPrice.id,
      itemName: t(creditsPrice.id),
      itemPrice: creditsPrice.nutaku_price.toString(),
      itemImage: getIconUrl(IconSrc.CreditsCoin),
      itemDescription: t(creditsPrice.id),
    },
    Type: 'credits',
  });

  const onBuy = () => {
    if (isNutaku) {
      nutakuCreatePayment();
    } else {
      window.open(creditsPrice.g2a_link, '_blank');
      const modal = addModal({
        children: <EnterCodePopup />,
      });
    }
  };

  return (
    <div className="tw-w-full tw-bg-gradient-to-b tw-from-horny-orange-400 tw-to-horny-orange-500 tw-pt-8 tw-pb-6 tw-px-2 tw-rounded-xl  tw-shadow-bezel tw-relative tw-overflow-hidden tw-flex tw-flex-col tw-gap-4">
      <SheenBlock />
      <div className="tw-font-bold tw-text-xl tw-w-full tw-text-center">
        {t(creditsPrice.id)}
      </div>
      <div className="tw-text-3xl tw-font-bold tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-z-10">
        <FontAwesomeIcon icon={faCoinVertical} />
        <div>{creditsPrice.credits}</div>
      </div>
      {creditsPrice.discount && (
        <div className="tw-flex tw-items-center tw-rounded-bl-xl tw-px-4 tw-py-2 tw-bg-horny-orange-700 tw-bg-opacity-25 tw-font-bold tw-text-xs tw-text-nowrap tw-w-fit tw-absolute tw-top-0 tw-right-0 tw-leading-none tw-capitalize tw-z-10">
          {creditsPrice.discount}
          {t('% discount')}
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-items-center tw-w-full tw-z-10">
        <TemptationsButton color="red" size="base" onClick={onBuy} width="full">
          {isNutaku ? (
            <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-w-full tw-justify-center">
              <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} />
              <div className="tw-font-bold tw-flex tw-flex-row tw-items-center tw-gap-2">
                <div>{creditsPrice.nutaku_price}</div>
                {creditsPrice.crossed_nutaku_price && (
                  <div className="tw-line-through tw-opacity-50">
                    {creditsPrice.crossed_nutaku_price}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-w-full tw-justify-center">
              <FontAwesomeIcon icon={faG2aLogo} size="2xs" />
              <div className="tw-font-bold tw-flex tw-flex-row tw-items-center tw-gap-2">
                <div>{creditsPrice.price}$</div>
                {creditsPrice.crossed_price && (
                  <div className="tw-line-through tw-opacity-50">
                    {creditsPrice.crossed_price}$
                  </div>
                )}
              </div>
            </div>
          )}
        </TemptationsButton>
      </div>
    </div>
  );
}
