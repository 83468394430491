import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../../context/Modal.Context';
import { useUserContext } from '../../../context/User.context';
import { Authorization } from '../../Authorization/Authorization';
import { TemptationsButton } from '../../Shared/TemptationsButton';
import { Subscriptions } from '../../Subscription/Subscriptions';

export function SubscriptionButton() {
  const { addModal } = useModalContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  return (
    <TemptationsButton
      color="red"
      size="xs"
      onClick={() => {
        addModal({
          showBalance: user?.IsAnonymous ? false : true,

          children: user!.IsAnonymous ? (
            <Authorization />
          ) : (
            <Subscriptions source="user_balance" />
          ),

          className: user!.IsAnonymous
            ? 'z-50 horny-bg-gradient_red'
            : 'horny-bg-dark_opac',
        });
      }}
    >
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <FontAwesomeIcon icon={faStars} />
        <div>{t('Subscribe')}</div>
      </div>
    </TemptationsButton>
  );
}
