import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import { useUserContext } from 'src/context/User.context';
import { setUpgradeProposal } from 'src/pages/Main/Proposal';
import { NutakuAPI } from 'src/services/API/NutakuAPI';
import { UserAPI } from 'src/services/API/UserAPI';
import { Nutaku, nutakuCreatePaymentOptions } from 'src/services/Nutaku/Nutaku';
import {
  WindowMessageEvent,
  WindowMessagePaymentFail,
  WindowMessagePaymentSuccess,
} from 'src/services/Window/Window';

type HandleNutakuPaymentProps = {
  NutakuPayment: nutakuCreatePaymentOptions;
  Type: 'subscription' | 'upgrade' | 'credits';
};

export function useNutakuPayment({
  NutakuPayment,
  Type,
}: HandleNutakuPaymentProps) {
  const queryClient = useQueryClient();
  const { user, setUser } = useUserContext();
  const { nutakuEnv, setIsPaymentFrame, isPaymentFrame } = useNutakuContext();
  const { capture } = useAnalyticsContext();
  const handleNutakuMessage = useCallback(
    (event: MessageEvent<any>) => {
      const message = event.data;
      console.log(message);
      let parsedMessage:
        | WindowMessagePaymentSuccess
        | WindowMessagePaymentFail
        | undefined = undefined;

      try {
        parsedMessage = JSON.parse(message) as
          | WindowMessagePaymentSuccess
          | WindowMessagePaymentFail;
      } catch (e) {}

      if (parsedMessage && parsedMessage.event === WindowMessageEvent.Payment) {
        if (parsedMessage.isOk === false) {
          setIsPaymentFrame(false);
        } else {
          window.removeEventListener('message', handleNutakuMessage);

          try {
            NutakuAPI.completeNutakuPurchase(parsedMessage.data.nutakuId).then(
              () => {
                UserAPI.get().then(({ data }) => setUser(data));
                // queryClient.invalidateQueries({
                //   queryKey: ['upgrades', 'subscription'],
                //   exact: false,
                //   refetchType: 'all',
                // });
                setIsPaymentFrame(false);
                setUpgradeProposal(1);
              }
            );
          } catch (e) {
            setIsPaymentFrame(false);
            // window.removeEventListener('message', handleNutakuMessage);
          }
        }
      } else {
        setIsPaymentFrame(false);

        // window.removeEventListener('message', handleNutakuMessage);
      }
    },
    [user, setUser, setIsPaymentFrame]
  );

  const nutakuCreatePayment = useCallback(() => {
    if (isPaymentFrame) {
      return;
    }
    setIsPaymentFrame(true);

    capture({
      event: 'subscribe_process',
      data: {
        state: 'Click_nutaku',
        level: NutakuPayment.itemName,
      },
    });

    if (nutakuEnv === NutakuEnv.Android) {
      Nutaku.createPayment(NutakuPayment);
      return;
    }
    if (nutakuEnv === NutakuEnv.Web) {
      window.addEventListener('message', handleNutakuMessage);

      window.parent.postMessage(
        JSON.stringify({
          action: 'payment',
          data: {
            purchase: {
              productId: NutakuPayment.itemId,
              nutakuCost: NutakuPayment.itemPrice,
            },
            nutakuData: {
              nutakuDescription: NutakuPayment.itemDescription,
              nutakuName: NutakuPayment.itemName,
              nutakuImageUrl: NutakuPayment.itemImage,
            },
          },
        }),
        '*'
      );
    }
  }, [NutakuPayment, isPaymentFrame, nutakuEnv, capture, handleNutakuMessage]);

  return {
    nutakuCreatePayment,
  };
}
